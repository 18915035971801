@font-face {
  font-family: "Lato";
  src: url("/Fonts/Lato-Regular.ttf");
}

:root{
  --white: #fff;
  --blue: #2F4097;
  --dark-blue: hwb(240 7% 85%);
  --yellow: #ff9900;
  --milky-white: #dde7f1;

    --card-width: 20rem;
  --card-height: 30rem;
  --card-transition-duration: 800ms;
  --card-transition-easing: ease;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    text-decoration: none;
    font-family: "Lato", sans-serif;
}

html{
  font-size: 62.5%;
}

body, html {
  scroll-behavior: smooth;
}

body {
  font-size: 1.6rem;
  width: 100%;
  font-family: 'Lato', sans-serif;
  letter-spacing: .04em;
  font-weight: normal;
  position: relative;
  color: var(--white);
  background-color: var(--blue);  
}

/* custom scroll bar */
::-webkit-scrollbar {
    width: 1.4rem;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background: #888;
    transition: all .5s ease-in-out;
    border-radius: 5%;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--blue);
}

section{
  padding: 10rem 0;
}


header {
  font-weight: 500;
  width: 100%;
  height: 8rem;
}

.logo-link {
  width: 22rem;
  height: 8rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0rem;
}

.logo {
  width: 100%;
  position: absolute;
  height: 50%;
  z-index: 20;
  object-fit: contain;
}

nav{
  z-index: 999;
  width: 100%;
  transition: background-color 800ms cubic-bezier(0.64,0.04,0.26,0.87);
  padding-block: 0;
  background-color: var(--blue);
  font-weight: 400;
  font-size: 1.7rem;
  transition: all 0.3s ease;
  position: fixed;
  letter-spacing: 1.5px;
}

nav.active{
  background-color: var(--blue);
  color: var(--white);
}

.nav-container {
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.nav-wrapper button {
  height: 100%;
}

.logo-link:focus-visible, 
.nav-link:focus-visible, .CTA:focus-visible {
  outline: 1px solid #FF9B09;
  outline-offset: 0.2em;
  place-items: center;
}

.nav-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2rem;
  transform: translate3d(0, -200%, 0);
  position: absolute;
  top: 5rem;
  left: 0;
  right: 0;
  z-index: 20;
  padding: 1.5rem;
  text-align: center;
  color: var(--white);
}

.nav-link {
  min-width: 7rem; 
  white-space: nowrap;
}

.nav-links.activated {
  transition: transform 0.4s cubic-bezier(0.64, 0.04, 0.26, 0.87);
  background-color: var(--blue);
}

.btn {
  color: var(--white);
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  padding: 1.6rem 2rem;
  background-color: transparent;
}

nav .btn--accent {
  border: 1px solid var(--white);
  padding: 1.6rem 3rem;
  font-weight: 500;
}

nav .btn--accent:hover {
  background-color: var(--white);
  color: var(--blue);
  font-weight: 500;
}

.btn--menu {
  color: var(--yellow);
  background-color: transparent;
  font-weight: 300;
  border: none;
  display: grid;
  place-items: center;
  padding-inline: 0rem;
  margin-right: 1rem;
  transition: transform 0.3s cubic-bezier(0.64, 0.04, 0.26, 0.87);
}

li[role="none"],
.nav-link {
  width: 100%;
  display: block;
  font-size: 1.6rem;
  color: var(--white);
  font-weight: 400;
}

.btn--menu[aria-expanded="true"]{
  transform: rotate(180deg);
}

.btn--menu[aria-expanded="true"] + .nav-links {
  transform: translate3d(0, 0, 0);
}

a {
  cursor: pointer;
}


nav a:hover {
  color: var(--yellow);
}

.services-drop a:hover {
  color: var(--white);
}

.nav-link[aria-current="page"] {
  color: var(--yellow);
  filter: brightness(120%);
}

.droplinks {
  padding: 1rem;
  font-size: 1.55rem;
  font-weight: 400;
  letter-spacing: 0;
}

.arrow {
  color: var(--white);
  font-size: 1.15rem;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  z-index: 1;
  background-color: rgba(18, 18, 39, 0.2);
  color: var(--white);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  margin-bottom: 1em;
  width: 90%;
  font-weight: 400;
}

.dropdown:hover > ul {
  display: block;
  width: 100%;
}

.dropdown-content li {
  display: block;
  margin: 0;
  background-color: var(--white);
}

.dropdown-content li a {
  display: block;
  padding: 1.4rem 0.5rem;
  color: var(--white);
  background-color: var(--blue);
  font-weight: 500;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;
}

.dropdown-content a:hover {
  background-color: var(--white);
  color: var(--blue);
}

.dropdown:nth-child(3) {
  position: relative;
}

.dropdown-content:nth-child(3) ul {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}

.scroll-up-btn{
  position: fixed;
  height: 4.5rem;
  width: 4.2rem;
  background: rgb(47, 64, 151);
  right: 2rem;
  bottom: 1rem;
  text-align: center;
  line-height: 45px;
  color: #fff;
  z-index: 9999;
  font-size: 3rem;
  border-radius: 6px;
  border-bottom-width: 2px;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;
}
.scroll-up-btn.show{
  bottom: 12rem;
  opacity: 1;
  pointer-events: auto;
}
.scroll-up-btn:hover{
  filter: brightness(90%);
}

/* home section styling */
.landing-home{
    background: url("/images/header.png") no-repeat;
    background-position: center -5rem;
    height: 100vh;
    max-height: 60rem;
    width: 100%;
    color: var(--white);
    background-size: cover;
    display: flex;
    flex-direction: column;
    top: 8rem;
    object-fit: contain;
    padding: 0;
}

.home-content {
    position: relative;
    width: 55%;
    height: 35rem;
    margin-top: 5rem;
  }

 .text-2 {
    position: absolute;
    left: 4rem;
    opacity: 0;
    transition: opacity 2s ease-in-out;
    width: 65rem;
    height: 100%;
    padding: 0em;
    filter: brightness(120%);
  }

  .text-2 img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .home-content .text-2.active {
    opacity: 1;
  }


.landing-home a{
    display: inline-block;
    color: var(--white);
    font-size: 1.6rem; 
    margin-left: 4rem;
    width: auto;
    transition: all 0.3s ease;
    margin-top: 4rem;
    padding: 1rem;
    font-weight: 500;
    letter-spacing: 1.5px;
}

.landing-home a i {
  border-radius: 50%;
  padding: 0.7rem; 
  background-color: var(--yellow); 
  color: var(--white); 
  position: relative; 
  left: 0.7rem; 
  font-size: 1.7rem; 
}

.landing-home a i:hover{
    color: var(--yellow);
    background-color: var(--dark-blue);
    transition: all 1s ease-in-out;
}

/* about section styling */

.landing-about {
   width: 100%;
   height: 60rem;
   display: flex;
   flex-direction: row;
   margin: 0;
   padding: 0;
   background-color: var(--white);
}

.landing-about-divs {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.q-mark {
  width: 40%;
  object-fit: contain;
}

.q-mark img {
  width: 100%;
  height: 80%;
  opacity: 1;
  object-fit: contain;
}

.trust {
  width: 60%;
}

.about-text {
  width: 100%;
  padding: 6rem;
  font-style: normal;
  word-spacing: .2rem;
  line-height: 2.5rem;
  font-weight: 400;
}

.why-trust-us-h {
  font-weight: 600; 
  font-size: 5.6rem; 
  width: 90%;
  color: var(--blue);
  margin-bottom: 5rem;
  text-align: left;
}

.trust-text {
  color: rgb(135, 135, 135);
  width: 90%;
  text-align: justify;
  font-size: 1.8rem;
  margin-bottom: 4rem;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 2.6rem;
}

.trust-btn-div {
  display: flex;
  justify-content: flex-start;
}

.trust-btn-div .learn-more-btn {
  background-color: white;
  color: var(--blue);
  border: 2px solid var(--blue);
  padding: 1.6rem 4rem;
  font-size: 1.7rem;
  font-weight: 400;
  z-index: 1;
  letter-spacing: 1px;
}

.learn-more-btn:hover {
  background-color: var(--blue);
  color: var(--white);
  transition: background-color 0.5s ease-in-out;
  cursor: pointer;
}

/*Provide section*/
  .landing-provide {
    width: 100%;
    min-width: 350px;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--white);
  }

  .provide-header {
    display: block; 
    width: 33rem; 
    height: 10rem;
    align-self: center;
    margin: 7rem 0rem 3.5rem 0rem;
    object-fit: contain;
  }

  .provide_number{
    background-color: var(--blue);
    color: var(--white);
    width: 8rem;
    height: 6rem;     
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.8rem; 
    margin-bottom: 2rem; 
    z-index: 2;
  }
  
  .provide-hr {
    background-color: #F0F0F0;
    height: 2px;
    border: none;
    width: 100%;
    margin-top: -55px;
    margin-bottom: 50px;
    z-index: 1;
    padding: 0;
  }

 .provide_container{
    background: #F3F4F9;
    width: 75rem;   
    height: 30rem;
    margin: 3rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .provide_content{
    color: var(--blue);
    justify-content: center;
    align-items: center;
    margin-bottom: 3.5rem;
    text-align: center;
  }

  .provide_content h3 {
    color: var(--blue);
    font-size: 3.5rem;
  }

  .provide_content h2 {
    color: var(--blue);
    font-size: 4rem;
  }

  .provide_content p{
    font-size: 1.8rem;
    color: var(--blue);
    margin-top: 2rem;
    font-weight: 400;
    letter-spacing: 1px;
  }

  .landing-provide a{
    display: flex;
    color: var(--yellow);
    width: auto;
    height: auto;
    max-width: 350px;
    transition: all 0.3s ease;
    padding: 1rem .5rem 1rem 1rem;
    font-weight: 400;
    margin-left: auto;
    margin-right: auto;
    letter-spacing: 1px;
}

.landing-provide a i {
  border-radius: 50%;
  padding: 0.6rem; 
  background-color: var(--yellow); 
  color: var(--white); 
  position: relative; 
  left: 1rem; 
  font-size: 1rem; 
}

.landing-provide a:hover{
  color: var(--yellow);
  transition: all 1s ease-in-out;
}

.landing-provide a i:hover{
  color: var(--yellow);
  background-color: var(--dark-blue);
  transition: all 1s ease-in-out;
}
   
/* Provide section ends here */

/* Our Process Section */
.our-process{
  width: 100%;
  height: auto;
  min-width: 350px;
  padding-top: 6rem ;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(to bottom right, #F3F4F9, #FEF8F2);
}

.process-header {
  display: block; 
  width: 33rem; 
  height: 10rem;
  align-items: center;
  margin: 3rem 0;
  object-fit: contain;
}

.process {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: auto;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.process-divs {
  height: 10rem;
  width: 90%;
  margin: .5rem 0rem;
  display: flex;
  flex-direction: row;
}

.arrow-mobile-align {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5%;
}

.text-mobile-align {
  display: flex;
  width: 95%;
}

.vr {
  display: none;
}

.integrity {
  border-top: 1px solid rgba(128, 128, 128, 0.2);
  border-bottom: 1px solid rgba(128, 128, 128, 0.2);
  border-width: 80%;
}

.process-arrow {
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.process-arrow i {
  font-size: 2.35rem;
  font-style: normal; 
  color: var(--blue);
  opacity: 1;
}

.process-h3 {
  width: 20%;
  display: flex;
  padding-left: 1.7rem;
  align-items: center;
  color: var(--blue);
  font-size: 1.75rem;
}

.process-p {
  width: 75%;
  display: flex;
  align-items: center;
  color: #676767;
  padding-left: 1.7rem;
  line-height: 2.5rem;
  font-size: 1.6rem;
}

.process-p p {
  max-width: 75rem;
}

/* Process style end */


/* Contact Section styling */
.contact{
  width: 100%;
  height: auto;
  min-width: 350px;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--white);
}

.contact-header {
  display: block; 
  width: 33rem; 
  height: 10rem;
  align-self: center;
  margin: 5rem 0;
  object-fit: contain;
}

.contact-container {
  display: flex;
  width: 80%;
  max-width: 120rem;
  height: 55rem;
  margin-bottom: 7rem;
  font-weight: 400;
  font-size: 1.6rem;
}

.contact-form {
  padding: 0rem 2rem 0rem 0rem; 
  background-color: var(--white);
  width: 60%;
  height: 100%;
}

.input-flex {
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: space-between;
margin-top: 3rem;
height: auto;
}

.input-flex-divs {
flex-basis: 49%;
}

.form-btn {
  background-color: var(--blue);
  height: 6rem;
  width: 16rem;
  border: none;
  font-size: 1.6rem;
  color: var(--white);
  padding: .48rem;
  letter-spacing: 1.5px;
  cursor: pointer;
  font-weight: 400;
  transition: background-color 0.3s ease-in-out;
  margin-top: 1rem;
  margin-bottom: 0rem;
  }
  
  .form-btn:hover {
  color: var(--blue);
  background-color: transparent;
  border: 2px solid var(--blue);
  }
  
  .contact-form input[type="text"],
  .contact-form input[type="email"],
  .contact-form input[type="tel"],
  .contact-form select,
  .contact-form textarea {
    padding: 1.6rem 0rem 1.6rem 2.5rem;
    font-size: 1.6rem;
    border: 1px solid rgba(103, 103, 103, 0.3);
    letter-spacing: 1px;
    caret-color: #2F4097;
  }

  .contact-form input::placeholder,
  .contact-form textarea::placeholder {
  color:  #BEBEBE; 
}
  
  .form-group {
    position: relative;
    margin-bottom: 1.25em;
    height: auto;
  }
  
  #textarea {
  height: 15rem;
  padding-left: 1.5rem;
  border: 1px solid rgba(103, 103, 103, 0.3);
  resize: none;
  overflow: auto;
  }
  
  textarea::placeholder {
  position: absolute;
  left: 2.5rem;
  }
  
  .form-group input, .form-group textarea {
    width: 100%;
    padding: 0.75em 1.25em;
    border: none;
    background-color: #ffffff;
    font-size: 1.6rem;
  }
  
  .form-group input:focus, .form-group textarea:focus {
    outline: none;
    box-shadow: 0 0 5
  }

  .input-label {
    color: var(--blue);
    margin: 1rem 0;
    font-weight: 400;
    font-size: 1.65rem;
  }

  .contact-info {
    background-color: var(--blue);
    color: var(--white);
    display: flex;
    width: 40%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
  }

  .center-info {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .info-header {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 3rem;
  }
  
  .reach-us-h {
    font-size: 2.5rem;
    letter-spacing: 1.5px;
    margin-top: 3rem;
    margin-bottom: 1.5rem;
    font-weight: 400;
    margin-left: 0rem;
  }
  
  .reach-us-p {
    font-size: 1.6rem;
    font-weight: 200;
    margin-bottom: 5rem;
    margin-left: 0rem;
  }
  
  .contact-info ul {
    list-style: none;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .contact-info ul li {
    width: 100%;
    margin: 1.5rem 0em;
    padding: 0;
  }
  
  .info-p {
    margin: 0rem 0rem 1rem 8rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap;
  }


  .contact-info a {
    color: var(--white);
    text-decoration: none;
    font-weight: 300;
    }
  
  .contact-info i {
    float: left;
    font-size: 2.5rem;
    margin-left: 2.2rem;
  }

.contact-info .place {
margin-top: 0rem;
}

.contact-info .call{
margin-top: 1.3rem;
}

.contact-info .mail{
margin-top: 1.3rem;
}

.contact-info a:hover {
  color: var(--yellow);
  font-weight: 300;
}

.info-social-icon {
  padding: 0;
  margin: 0;
}

.info-social-icon i{
  color: rgba(0, 0, 0, 0.5);
  background-color: var(--white);
  font-size: 1.4rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 1rem; 
  margin-top: 3rem;
}

.info-social-icon i:hover{
  color: var(--yellow);
  transition: background-color 0.3s ease-in-out;
}

/* Companies-worked-with carousel */
.sponsors {
  width: 100%;
  height: 30rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--white);
}

.companies-h {
  width: 100%;
  height: auto;
  padding: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 2rem;
  font-size: 1.8rem;
  color: var(--blue);
}

.sponsors-container {
  display: flex;
  flex-direction: row;
  animation: scroll 30s linear infinite;
  height: auto;
  object-fit: contain;
  margin-top: 2rem;
}

.sponsors-container img {
  margin: 0 3rem;
  width: 18rem;
  height: 10rem;
  object-fit: contain;
  background-position: center;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}


/* footer section styling */
footer {
  width: 100%;
  height: 55rem;
  display: flex;
  flex-direction: column;
  background-color: var(--blue);
  padding: 0rem 12rem;
}

.footer-divs {
  height: 80%;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.logo-socials {
  background-color: var(--blue);
  height: 20%;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid #3A4A9C;
}

.footer-logo {
  width: 15rem;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-logo-img {
  background-color: var(--blue);
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.footer-socials {
  width: auto;
  height: auto;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-socials i {
  color: rgba(255, 255, 255, 0.7);
  background-color: var(--blue);
  border: 1px solid rgba(255, 255, 255, 0.7);
  margin: 0 .8rem;
  font-size: 1.4rem;
  width: 2.6rem;
  height: 2.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 1.4rem;
}

.footer-socials i:hover {
  color: var(--yellow);
  transition: background-color 0.3s ease-in-out;
}

.footer-link-divs {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.footer-link-div {
  height: 100%;
  width: calc(100% / 3);
}

.footer-links-hr {
  display: none;
}

.footer-link-divs a {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
}

.footer-link-divs a:hover {
  color: var(--yellow);
}

.footer-company,
.footer-product,
.footer-resources {
  display: flex;
  flex-direction: column;
  padding-top: 10rem;
  align-items: flex-start;
}

.footer-company h5,
.footer-product h5,
.footer-resources h5 {
  font-size: 1.8rem;
  letter-spacing: .05rem;
  color: var(--milky-white);
  margin-bottom: 4rem;
}

.footer-company ul,
.footer-product ul,
.footer-resources ul {
  list-style: none;
  display: flex;
  flex-direction: column;
}

.footer-company li,
.footer-product li,
.footer-resources li {
  margin: .8rem 0rem;
  font-size: 1.5rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
}

.mission-vision-div {
  width: 30%;
  display: flex;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
} 

.mission-vision-container {
  width: 90%;
  max-width: 35rem;
  height: 32rem;
  background: rgba(255, 255, 255, 0.1);
}

.mission,
.vision {
  width: 100%;
  height: 50%;
  padding: 0rem 2rem;
}

.mission h5,
.vision h5 {
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 2.5rem;
}

.mission p,
.vision p {
  font-size: 1.45rem;
  line-height: 2.2rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
}


.mission {
  padding-top: 2rem;
}

.copyright {
  width: 100%;
  height: 5rem;
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #676767;
  font-weight: 400;
  background-color: var(--white);
}

.copyright span {
  color: var(--blue);
  margin-left: .5rem;
}








@media screen and (min-width:350px) and (max-width:600px){
  nav{
    height: 7rem;
    font-weight: 400;
    font-size: 1.5rem;
    position: fixed;
    letter-spacing: 1px;
    background-color: rgba(47, 64, 151, 0.2);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
  }


  .logo-link {
    width: 10rem;
  }

  .logo {
    margin-left: 4rem;
    margin-top: 0;
    z-index: 20;
  }

  .btn {
    padding: 1em 0.5em;
  }

  li[role="none"],
  .nav-link {
    font-size: 1.4rem;
    font-weight: 400;
}

  .dropdown-content {
    position: static;
    width: 100%;
    transition: all 0.3s ease;
  }

  .dropdown-content li a {
    color: var(--white);
    background-color: var(--blue);
    box-shadow: none;
}

/* home section styling */
.landing-home {
  max-height: 50rem;
  top: 2rem;
  padding: 0;
  background-position: -17rem 7rem;
}

.home-content {
  height: 20rem;
  margin-top: 6rem;
  width: 100%;
}

.text-2 {
  width: 80%;
  height: 100%;
  left: 2rem;
}

.landing-home a {
  font-size: 1.5rem; 
  margin-left: 2rem;
  margin-top: 4rem;
  height: 5rem;
}

.landing-home a i {
  font-size: 1.5rem; 
  padding: .7rem;
  left: .5rem;
}

.landing-home a i:active{
  color: var(--yellow);
  background-color: var(--dark-blue);
  transition: all 1s ease-in-out;
}

/* About section */

.landing-about {
  position: relative;
  height: auto;
  flex-direction: column;
}

.landing-about .about-divs {
  justify-content: center;
  align-items: center;
}

.landing-about .q-mark {
  display: flex;
  width: 100%;
  height: 17rem;
}

.q-mark .question-img {
  width: 17rem;
}

.landing-about .about-text {
  padding: 2rem;
  word-spacing: 0;
  line-height: 2rem;
  text-align: center;
}

.trust {
  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.why-trust-us-h {
  font-size: 2.5rem;
  margin-top: 2rem;
  margin-bottom: 3rem;
  text-align: center;
  width: 100%;
}

.trust-text {
  font-size: 1.45rem;
  margin-bottom: 5rem;
  width: 100%;
  line-height: 2.3rem;
  text-align: center;
}

.trust-btn-div {
  justify-content: center;
}

.trust-btn-div .learn-more-btn {
  padding: 1.5rem 2rem;
  font-size: 1.5rem;
  margin-bottom: 4rem;
  background-color: var(--blue);
  color: var(--white);
}

/* Provide section */

.provide-header {
  width: 19rem; 
  height: 8rem;
  align-items: center;
  margin-top: 0rem ;
  margin-bottom: .5rem;
}

.provide_number{
  width: 4rem;
  height: 3.5rem;     
  margin-bottom: 2rem; 
  font-size: 1.6rem;
}

.provide-hr {
  margin-top: -42px;
  margin-bottom: 50px;
}

.provide_container{
  width: 90%;   
  height: 25rem;
  margin: 0rem 0rem 4rem 0rem;
  padding: 1rem;
}

.provide_content{
  margin-bottom: 2rem;
}

.provide_content h3 {
  font-size: 2rem;
}

.provide_content h2 {
  font-size: 2.5rem;
}

.provide_content p{
  font-size: 1.5rem;
  margin-top: 2rem;
  line-height: 2rem;
  letter-spacing: .4px;
}

.landing-provide a{
  padding: 1rem;
  font-weight: 400;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: .5px;
}

.landing-provide a i {
  padding: 0; 
  left: 1rem; 
  background-color: transparent;
  color: var(--yellow);
  font-size: 2rem;
}

/* Our Process section */
.our-process {
  padding: 0;
}

.process-header {
  display: block; 
  width: 18rem; 
  height: 8rem;
  align-items: center;
  margin-top: 1rem;
}

.process {
  justify-content: flex-start;
  width: 90%;
  margin-bottom: 8rem;
  background-color: transparent;
}

.process-divs {
  width: 100%;
  flex-direction: row;
  height: 23rem;
  margin: 2rem 0rem;
  background-color: #ffffff;
}

.arrow-mobile-align {
  width: 12%;
  height: 100%;
  padding-top: 0rem;
  justify-content: center;
}

.vr {
  height: 60%;
  width: 1px;
  background-color: var(--blue);
  display: block;
  margin: auto 0;
}

.text-mobile-align {
  flex-direction: column;
  width: 86%;
  justify-content: center;
  padding-left: 1rem;
  align-items: center;
}

.process-arrow {
  width: 100%;
  font-size: 1.4rem;
  padding: .5rem;
}

.process-h3 {
  width: 100%;
  padding-left: 1rem;
  justify-content: flex-start;
  font-size: 1.6rem;
  margin-bottom: 1rem;
}

.process-p {
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0rem 1rem;
  max-width: 450px;
  font-size: 1.5rem;
}

/* Contact section */
.contact {
  justify-content: space-around;
  align-items: center;
}

.contact-header {
  width: 18rem;
  height: 8rem;
  margin: 2rem 0 0 0;
}

.contact-container {
  width: 100%;
  height: auto;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.contact-form {
  width: 90%; 
  height: auto;
  padding: 0;
  margin: 0 auto 2rem auto;
}

.contact-info {
  width: 90%;
  height: auto;
}

.contact-info ul {
  justify-content: center;
  align-items: center;
  height: 55rem;
  padding: 0 2rem;
}


.info-p {
  margin-left: 4.2rem;
  line-height: 2.5rem;
  width: 90%;
  font-size: 1.5rem;
}

.info-header {
  padding: 0;
}

.reach-us-h {
  margin-left: 0rem;
}

.reach-us-p {
  margin-left: 0rem;
  margin-bottom: 3rem;
}

.contact-info i {
  margin-left: .8rem;
  font-size: 1.8rem;
}


.input-flex {
  flex-direction: column;
}

.input-flex-divs {
  flex-basis: 100%;
}

.form-btn {
  width: 14rem;
  height: 5rem;
  margin-bottom: 5rem;
  font-size: 1.5rem;
  letter-spacing: .5px;
}

.contact-form input[type="text"],
  .contact-form input[type="email"],
  .contact-form input[type="tel"],
  .contact-form select,
  .contact-form textarea {
    padding: 1.4rem 0rem 1.4rem 2rem;
    font-size: 1.5rem;
    letter-spacing: .5px;
}

#textarea {
  height: 13rem;
  padding-left: 2rem;
  position: relative;
}

textarea::placeholder {
  position: absolute;
  left: 2rem;
}

textarea::-webkit-input-placeholder {
  text-indent: 0;
  left: 2rem;
}

textarea:placeholder-shown {
  padding: 2rem;
}



.form-group input, .form-group textarea {
  font-size: 1.55rem;
}

.input-label {
  margin: 1rem 0;
  font-size: 1.55rem;
}

.input-flex {
  flex-direction: column;
}

.input-flex-divs {
  flex-basis: 100%;
}

.contact-info .fa-map-marker-alt {
  margin-top: 0rem;
  }
  
  .contact-info .fa-phone{
  margin-top: 1.6rem;
  }
  
  .contact-info .fa-envelope {
  margin-top: 1.5rem;
  }

.info-social-icon i{
  font-size: 1.4rem;
  width: 2.5rem;
  height: 2.5rem; 
  margin-right: 1rem;
  margin-top: 1rem;
}

/* Companies-worked-with carousel */
.sponsors {
  height: 25rem;
}

.companies-h {
  font-size: 1.6rem;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.sponsors-container img {
  margin: 0 2rem;
  width: 15rem;
  height: 10rem;
}

.sponsors-container {
  animation-duration: 10s;
}


/* Footer section */
footer {
  height: auto;
  padding: 0;
}

.footer-divs {
  flex-direction: column;
}

.logo-socials {
  width: 90%;
  height: 10rem;
}

.footer-logo {
  width: 12rem;
  height: 6rem;
  margin-top: 0rem;
}

.footer-socials {
  width: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.footer-socials i {
  margin: 0 .5rem;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1rem;
  padding: 1rem;
}

.footer-link-divs {
  width: 100%;
  height: auto;
  flex-direction: row;
  padding-top: 2rem;
  justify-content: center;
  align-items: flex-start;
}

.footer-link-div {
  margin: 0;
  padding: 4rem 0 2rem 0;
}

.footer-company,
.footer-product,
.footer-resources {
  display: flex;
  flex-direction: column;
  padding-top: 0rem;
  align-items: center;
  justify-content: center;
  padding-left: 0rem;
}

.footer-company h5,
.footer-product h5,
.footer-resources h5 {
  font-size: 1.6rem;
  letter-spacing: .05rem;
  color: rgba(255, 255, 255, 0.5);
  margin: 1rem 0;
}

.footer-company li,
.footer-product li,
.footer-resources li {
  margin: .7rem 0rem;
  font-size: 1rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 1);
  line-height: 2rem;
}


.mission-vision-div {
  width: 100%;
  height: 38rem;
  padding: 2rem 0;
  justify-content: center;
}

.mission-vision-container {
  min-width: 30rem;
  max-width: 60rem;
  width: 85%;
  height: 32rem;
  background: rgba(255, 255, 255, 0.1);
  margin-left: 0rem;
}

.mission h5,
.vision h5 {
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 2.2rem;
}

.mission p,
.vision p {
  font-size: 1.4rem;
  line-height: 2.3rem;
}



/* copyright */
.copyright {
  height: 5.5rem;
  font-size: 1.2rem;
}

}

@media screen and (min-width:601px) and (max-width:768px){
  nav{
    height: 7rem;
    font-weight: 400;
    font-size: 1.5rem;
    position: fixed;
    letter-spacing: 1px;
    background-color: rgba(47, 64, 151, 0.2);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
  }


  .logo-link {
    width: 10rem;
  }

  .logo {
    margin-left: 4rem;
    margin-top: 0;
    z-index: 20;
  }

  .btn {
    padding: 1em 0.5em;
  }

  li[role="none"],
  .nav-link {
    font-size: 1.5rem;
    font-weight: 400;
}

.dropdown-content {
  position: static;
  width: 100%;
  transition: all 0.3s ease;
}

  .dropdown-content li a {
    color: var(--white);
    background-color: var(--blue);
    box-shadow: none;
}

/* home section styling */
.landing-home {
    max-height: 50rem;
    top: 2rem;
    padding: 0;
    background-position: -17rem 7rem;
}

.home-content {
  height: 20rem;
  margin-top: 6rem;
  width: 100%;
}

.text-2 {
  width: 75%;
  height: 100%;
  left: 2rem;
}

.landing-home a {
  font-size: 1.5rem; 
  margin-left: 2rem;
  margin-top: 4rem;
  width: 20rem;
  height: 5rem;
}

.landing-home a i {
  font-size: 1.5rem; 
  padding: .7rem;
  left: .5rem;
}

.landing-home a i:active{
  color: var(--yellow);
  background-color: var(--dark-blue);
  transition: all 1s ease-in-out;
}

/* About section */

.landing-about {
  position: relative;
  height: auto;
  flex-direction: column;
}

.landing-about .about-divs {
  justify-content: center;
  align-items: center;
}

.landing-about .q-mark {
  display: flex;
  width: 100%;
  height: 17rem;
}

.q-mark .question-img {
  width: 17rem;
}

.landing-about .about-text {
  width: 100%;
  max-width: 65rem;
  margin: 0 auto;
  word-spacing: 0;
  line-height: 2.3rem;
  padding: 2rem;
}

.trust {
  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.why-trust-us-h {
  font-size: 2.6rem;
  margin-top: 2rem;
  margin-bottom: 3rem;
  text-align: center;
  width: 100%;
}

.trust-text {
  font-size: 1.55rem;
  margin-bottom: 5rem;
  width: 100%;
  align-items: center;
  line-height: 2.5rem;
}

.trust-btn-div {
  justify-content: center;
}

.trust-btn-div .learn-more-btn {
  padding: 1.5rem 2rem;
  font-size: 1.5rem;
  margin-bottom: 3rem;
  background-color: var(--blue);
  color: var(--white);
}

/* Provide section */

.provide-header {
  width: 20rem; 
  height: 8rem;
  align-items: center;
  margin-top: 0rem ;
  margin-bottom: .5rem;
}

.provide_number{
  width: 4rem;
  height: 3.5rem;     
  margin-bottom: 2rem; 
  font-size: 1.6rem;
}

.provide-hr {
  margin-top: -42px;
  margin-bottom: 50px;
}

.provide_container{
  width: 90%;   
  height: 25rem;
  margin: 0rem 0rem 4rem 0rem;
  padding: 1rem;
}

.provide_content{
  margin-bottom: 2rem;
}

.provide_content h3 {
  font-size: 2rem;
}

.provide_content h2 {
  font-size: 2.5rem;
}

.provide_content p{
  font-size: 1.5rem;
  margin-top: 2rem;
  line-height: 2rem;
  letter-spacing: .4px;
}

.landing-provide a{
  padding: 1rem;
  font-weight: 400;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: .5px;
}

.landing-provide a i {
  padding: 0; 
  left: 1rem; 
  background-color: transparent;
  color: var(--yellow);
  font-size: 2rem;
}

/* Our Process section */
.our-process {
  padding: 0;
}


.process-header {
  display: block; 
  width: 18rem; 
  height: 8rem;
  align-items: center;
  margin-top: 1rem;
}

.process {
  justify-content: flex-start;
  width: 90%;
  margin-bottom: 8rem;
  background-color: transparent;
}

.process-divs {
  width: 100%;
  flex-direction: row;
  height: 23rem;
  margin: 2rem 0rem;
  background-color: #ffffff;
}

.arrow-mobile-align {
  width: 12%;
  height: 100%;
  padding-top: 0rem;
  justify-content: center;
}

.vr {
  height: 60%;
  width: 1px;
  background-color: var(--blue);
  display: block;
  margin: auto 0;
}

.text-mobile-align {
  flex-direction: column;
  width: 86%;
  justify-content: center;
  padding-left: 1rem;
  align-items: center;
}

.process-arrow {
  width: 100%;
  font-size: 1.4rem;
  padding: .5rem;
}

.process-h3 {
  width: 100%;
  padding-left: 1rem;
  justify-content: flex-start;
  font-size: 1.6rem;
  margin-bottom: 1rem;
}

.process-p {
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0rem 1rem;
  max-width: 450px;
  font-size: 1.5rem;
}

/* Contact section */
.contact {
  justify-content: space-around;
  align-items: center;
}

.contact-header {
  width: 18rem;
  height: 8rem;
  margin: 2rem 0 0 0;
}

.contact-container {
  width: 100%;
  height: auto;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.contact-form {
  width: 90%; 
  height: auto;
  padding: 0;
  margin: 0 auto 2rem auto;
}

.contact-info {
  width: 90%;
  /* max-width: 55rem; */
  height: auto;
  padding: 2rem 0;
}

.contact-info ul {
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 0 4rem;
}


.info-p {
  margin-left: 5rem;
  line-height: 2.5rem;
  width: 90%;
  font-size: 1.6rem;
}

.info-header {
  padding: 0;
}

.reach-us-h {
  margin-left: 0rem;
}

.reach-us-p {
  margin-left: 0rem;
  margin-bottom: 3rem;
}

.contact-info i {
  margin-left: 0rem;
  font-size: 1.8rem;
}


.input-flex {
  flex-direction: column;
}

.input-flex-divs {
  flex-basis: 100%;
}

.form-btn {
  width: 14rem;
  height: 5rem;
  margin-bottom: 5rem;
  font-size: 1.5rem;
  letter-spacing: .5px;
}

.contact-form input[type="text"],
  .contact-form input[type="email"],
  .contact-form input[type="tel"],
  .contact-form select,
  .contact-form textarea {
    padding: 1.4rem 0rem 1.4rem 2rem;
    font-size: 1.5rem;
    letter-spacing: .5px;
}

#textarea {
  height: 13rem;
  padding-left: 2rem;
  position: relative;
}

textarea::placeholder {
  position: absolute;
  left: 2rem;
}


textarea::-webkit-input-placeholder {
  text-indent: 0;
  left: 2rem;
}

textarea:placeholder-shown {
  padding: 2rem;
}

.form-group input, .form-group textarea {
  font-size: 1.55rem;
}

.input-label {
  margin: 1rem 0;
  font-size: 1.55rem;
}

.input-flex {
  flex-direction: column;
}

.input-flex-divs {
  flex-basis: 100%;
}

.contact-info .fa-map-marker-alt {
  margin-top: 0rem;
  }
  
  .contact-info .fa-phone{
  margin-top: 1.6rem;
  }
  
  .contact-info .fa-envelope {
  margin-top: 1.5rem;
  }

.info-social-icon i{
  font-size: 1.4rem;
  width: 2.5rem;
  height: 2.5rem; 
  margin-right: 1rem;
  margin-top: 1rem;
}

/* Companies-worked-with carousel */
.sponsors {
  height: 25rem;
}

.companies-h {
  font-size: 1.6rem;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.sponsors-container img {
  margin: 0 2rem;
  width: 15rem;
  height: 10rem;
}

.sponsors-container {
  animation-duration: 8s;
}

/* Footer section */
footer {
  height: auto;
  padding: 0;
}

.footer-divs {
  flex-direction: column;
}

.logo-socials {
  width: 90%;
  height: 10rem;
}

.footer-logo {
  width: 12rem;
  height: 6rem;
  margin-top: 0rem;
}

.footer-socials {
  width: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.footer-socials i {
  margin: 0 .5rem;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1rem;
  padding: 1rem;
}

.footer-link-divs {
  width: 100%;
  height: auto;
  flex-direction: row;
  padding-top: 2rem;
  justify-content: center;
  align-items: flex-start;
}

.footer-link-div {
  margin: 0;
  padding: 4rem 0 2rem 0;
}

.footer-company,
.footer-product,
.footer-resources {
  display: flex;
  flex-direction: column;
  padding-top: 0rem;
  align-items: center;
  justify-content: center;
  padding-left: 0rem;
}

.footer-company h5,
.footer-product h5,
.footer-resources h5 {
  font-size: 1.6rem;
  letter-spacing: .05rem;
  color: rgba(255, 255, 255, 0.5);
  margin: 2rem 0;
}

.footer-company li,
.footer-product li,
.footer-resources li {
  margin: 1rem 0;
  font-size: 1.4rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 1);
  line-height: 2rem;
}


.mission-vision-div {
  width: 100%;
  height: 38rem;
  padding: 2rem 0;
  justify-content: center;
}

.mission-vision-container {
  min-width: 30rem;
  max-width: 60rem;
  width: 85%;
  height: 32rem;
  background: rgba(255, 255, 255, 0.1);
  margin-left: 0rem;
}

.mission h5,
.vision h5 {
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 2.2rem;
}

.mission p,
.vision p {
  font-size: 1.5rem;
  line-height: 2.3rem;
}



/* copyright */
.copyright {
  height: 5rem;
  font-size: 1.3rem;
}

}

@media screen and (min-width:769px) and (max-width:992px){
  
  nav{
    height: 8rem;
    font-weight: 400;
    font-size: 1.5rem;
    position: fixed;
    letter-spacing: 1px;
  }

  .logo-link {
    width: 15rem;
  }

  .logo {
    margin-left: 4rem;
    margin-top: 0;
    z-index: 20;
  }

  .btn--menu{
    display: none;
  }

  li[role="none"], 
  .nav-link {
    font-size: 1.5rem;
    font-weight: 400;
  }

  .nav-links {
    position: static;
    transform: translate3d(0, 0, 0);
    flex-direction: row;
    border: 0;
    z-index: 0;
    padding: 0;
    inset: 0;
    background-color: transparent;
    margin-right: 2rem;
  }

  .home-content {
    width: 60%;
    height: 35rem;
    margin-top: 4rem;
  }

  .text-2 {
    width: 100%;
  }
 
.landing-home a {
  width: 20rem;
  font-size: 1.5;
  padding: 0;
}

/* about section styling */

.landing-about {
  width: 100%;
  height: 50rem;
}

.q-mark img {
  width: 90%;
  height: 65%;
  opacity: 1;
  object-fit: contain;
}

.about-text {
  padding: 6rem 3rem;
  font-style: normal;
  word-spacing: .2rem;
  line-height: 2.5rem;
}

.why-trust-us-h {
  font-weight: 600; 
  font-size: 3rem; 
  line-height: 3.2rem;
  width: 95%;
  margin-bottom: 2rem;
}

.trust-text {
  width: 95%;
  text-align: justify;
  font-size: 1.6rem;
  margin-bottom: 2.5rem;
  font-weight: 400;
  line-height: 2.4rem;
}

.trust-btn-div .learn-more-btn {
  padding: 1.6rem 4rem;
  font-size: 1.6rem;
}

/*Provide section*/
.provide-header {
  display: block; 
  width: 25rem; 
  height: 9rem;
  align-self: center;
  margin: 0rem 0rem 3rem 0rem;
  object-fit: contain;
}

.provide_number{
  width: 6rem;
  height: 5rem;     
  font-size: 2.5rem; 
  margin-bottom: 1.6rem; 
}

.provide-hr {
  margin-top: -42px;
  margin-bottom: 50px;
}


.provide_container{
  width: 90%;   
  height: 30rem;
  max-width: 80rem;
  margin: 0rem 0rem 4rem 0rem;
  padding: 1rem;
}

.provide_content{
  margin-bottom: 2rem;
}

.provide_content h3 {
  font-size: 2rem;
}

.provide_content h2 {
  font-size: 2.5rem;
}

.provide_content p{
  font-size: 1.5rem;
  margin-top: 2rem;
  line-height: 2rem;
  letter-spacing: .4px;
}

.landing-provide a{
  padding: 1rem;
  font-weight: 400;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: .5px;
}

.landing-provide a i {
  padding: 0; 
  left: 1rem; 
  background-color: transparent;
  color: var(--yellow);
  font-size: 2rem;
}

/* Our Process Section */
.our-process {
  padding-top: 2rem;
  padding-bottom: 6rem;
}

.process-header {
  display: block; 
  width: 25rem; 
  height: 8rem;
  align-items: center;
  margin: 2rem 0;
  object-fit: contain;
}

.process {
  width: 90%;

}

.process-divs {
  height: 10rem;
  width: 95%;
  margin: .5rem 0rem;
}

.process-arrow i {
  font-size: 2rem;
}

.process-h3 {
  width: 25%;
  padding: 0 1.5rem;
  font-size: 1.75rem;
  line-height: 2.6rem;
}

.process-p {
  width: 75%;
  padding: 0 1.5rem;
}

/* Contact Section styling */
.contact {
  width: 100%;
  height: auto;
}

.contact-header {
  display: block; 
  width: 25rem; 
  height: 8rem;
  align-self: center;
  margin: 3rem 0;
  object-fit: contain;
}

.contact-container {
  display: flex;
  width: 90%;
  height: 57rem;
  margin-bottom: 7rem;
  font-weight: 400;
  font-size: 1.5rem;
}

.contact-form {
  width: 55%;
}

.contact-form input[type="text"],
  .contact-form input[type="email"],
  .contact-form input[type="tel"],
  .contact-form select,
  .contact-form textarea {
    padding: 1.6rem 0rem 1.6rem 1.5rem;
    font-size: 1.6rem;
    border: 1px solid rgba(103, 103, 103, 0.3);
    letter-spacing: .5px;
  }

  textarea::placeholder {
    left: 1.5rem;
    }

.contact-info {
  width: 45%;
}

.center-info {
  width: 100%;
  height: 85%;
}

.info-header {
  padding-left: 2rem;
}

.reach-us-h {
  font-size: 2.2rem;
  letter-spacing: 1.5px;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.reach-us-p {
  font-size: 1.4rem;
  margin-bottom: 3rem;
}

.info-p {
  margin: 0rem 0rem 1rem 7rem;
}

.info-social-icon i{
  margin-top: 1rem;
}

/* Companies-worked-with carousel */
.sponsors {
  height: 25rem;
}

.companies-h {
  font-size: 1.6rem;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.sponsors-container img {
  margin: 0 2rem;
  width: 15rem;
  height: 10rem;
}

.sponsors-container {
  animation-duration: 12s;
}

/* footer */
footer {
  width: 100%;
  height: 55rem;
  display: flex;
  flex-direction: column;
  background-color: var(--blue);
  padding: 0rem 4rem;
}

.footer-divs {
  height: 85%;
}


.logo-socials {
  width: 100%;
  height: 15%;
}

.footer-logo {
  width: 14rem;
  height: 6rem;
  margin-top: 0rem;
}

.footer-socials {
  width: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.footer-socials i {
  margin: 0 .5rem;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.2rem;
  padding: 1rem;
}

.footer-link-div {
  width: 65%;
}

.footer-company,
.footer-product,
.footer-resources {
  padding-top: 5rem;
}

.footer-company h5,
.footer-product h5,
.footer-resources h5 {
  font-size: 1.8rem;
  margin-bottom: 2rem;
}

.footer-company li,
.footer-product li,
.footer-resources li {
  margin: 1rem 0rem;
  font-size: 1.5rem;
}

.mission-vision-div {
  width: 35%;
} 

.mission-vision-container {
  width: 90%;
  max-width: 35rem;
  height: 100%;
  max-height: 35rem;
}

.mission,
.vision {
  width: 100%;
  height: 50%;
  padding: 0rem 2rem;
}

.mission h5,
.vision h5 {
  font-size: 1.6rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

}

@media screen and (min-width:993px){
  nav{
    height: 8rem;
    font-weight: 400;
    font-size: 1.5rem;
    position: fixed;
    letter-spacing: 1px;
  }

  .logo-link {
    width: 15rem;
  }

  .logo {
    margin-left: 8rem;
    margin-top: 0;
    z-index: 20;
  }

  .btn--menu{
    display: none;
  }

  li[role="none"], 
  .nav-link {
    font-size: 1.5rem;
    font-weight: 400;
  }

  .nav-links {
    position: static;
    transform: translate3d(0, 0, 0);
    flex-direction: row;
    border: 0;
    z-index: 0;
    padding: 0;
    inset: 0;
    background-color: transparent;
    margin-right: 2rem;
  }

  /* Home section */

  /* .home-content {
    width: 60%;
    height: 35rem;
    margin-top: 4rem;
  }

  .text-2 {
    width: 100%;
  }
  */
.landing-home a {
  width: 20rem;
  font-size: 1.5;
  padding: 0;
}

  /* about section styling */
.about-text {
  padding: 6rem 3rem;
  font-style: normal;
  word-spacing: .2rem;
  line-height: 2.5rem;
}

.trust-btn-div .learn-more-btn {
  padding: 1.6rem 4rem;
  font-size: 1.6rem;
}

  /*Provide section*/
/* .provide-header {
  display: block; 
  width: 25rem; 
  height: 9rem;
  align-self: center;
  margin: 0rem 0rem 3rem 0rem;
  object-fit: contain;
}

.provide_number{
  width: 6rem;
  height: 5rem;     
  font-size: 2.5rem; 
  margin-bottom: 1.6rem; 
}

.provide-hr {
  margin-top: -42px;
  margin-bottom: 50px;
}


.provide_container{
  width: 90%;   
  height: 30rem;
  max-width: 80rem;
  margin: 0rem 0rem 4rem 0rem;
  padding: 1rem;
}

.provide_content{
  margin-bottom: 2rem;
}

.provide_content h3 {
  font-size: 2rem;
}

.provide_content h2 {
  font-size: 2.5rem;
}

.provide_content p{
  font-size: 1.5rem;
  margin-top: 2rem;
  line-height: 2rem;
  letter-spacing: .4px;
}

.landing-provide a{
  padding: 1rem;
  font-weight: 400;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: .5px;
}

.landing-provide a i {
  padding: 0; 
  left: 1rem; 
  background-color: transparent;
  color: var(--yellow);
  font-size: 2rem;
} */

  /* Our Process Section */
.our-process {
  padding-top: 2rem;
  padding-bottom: 6rem;
}

.process-header {
  display: block; 
  width: 25rem; 
  height: 8rem;
  align-items: center;
  margin: 2rem 0;
  object-fit: contain;
}

.process {
  width: 90%;

}

.process-divs {
  height: 10rem;
  width: 95%;
  margin: .5rem 0rem;
}

.process-arrow i {
  font-size: 2rem;
}

.process-h3 {
  width: 25%;
  padding: 0 1.5rem;
  font-size: 1.75rem;
  line-height: 2.6rem;
}

.process-p {
  width: 75%;
  padding: 0 1.5rem;
}


    /* Contact Section styling */
.contact {
  width: 100%;
  height: auto;
}

.contact-header {
  display: block; 
  width: 25rem; 
  height: 8rem;
  align-self: center;
  margin: 3rem 0;
  object-fit: contain;
}

.contact-container {
  display: flex;
  width: 80%;
  height: 57rem;
  margin-bottom: 7rem;
  font-weight: 400;
  font-size: 1.5rem;
}

.contact-form {
  width: 60%;
}

.contact-form input[type="text"],
  .contact-form input[type="email"],
  .contact-form input[type="tel"],
  .contact-form select,
  .contact-form textarea {
    padding: 1.6rem 0rem 1.6rem 1.5rem;
    font-size: 1.6rem;
    border: 1px solid rgba(103, 103, 103, 0.3);
    letter-spacing: .5px;
  }

  textarea::placeholder {
    left: 1.5rem;
    }

.contact-info {
  width: 40%;
}

.center-info {
  width: 80%;
  max-width: 50rem;
  height: 85%;
}

.info-header {
  padding-left: 2rem;
}

.reach-us-h {
  font-size: 2.2rem;
  letter-spacing: 1.5px;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.reach-us-p {
  font-size: 1.4rem;
  margin-bottom: 3rem;
}

.info-p {
  margin: 0rem 0rem 1rem 7rem;
}

.info-social-icon i{
  margin-top: 1rem;
}

  /* Companies-worked-with carousel */
  .sponsors {
    height: 25rem;
  }

  .companies-h {
    font-size: 1.6rem;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .sponsors-container img {
    margin: 0 2rem;
    width: 15rem;
    height: 10rem;
  }

  .sponsors-container {
    animation-duration: 15s;
  }

  /* footer */
footer {
  width: 100%;
  height: 55rem;
  display: flex;
  flex-direction: column;
  background-color: var(--blue);
  padding: 0rem 10rem;
}

.footer-divs {
  height: 85%;
}


.logo-socials {
  width: 100%;
  height: 15%;
}

.footer-logo {
  width: 14rem;
  height: 6rem;
  margin-top: 0rem;
}

.footer-socials {
  width: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.footer-socials i {
  margin: 0 .5rem;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.2rem;
  padding: 1rem;
}

.footer-link-div {
  width: 65%;
}

.footer-company,
.footer-product,
.footer-resources {
  padding-top: 5rem;
}

.footer-company h5,
.footer-product h5,
.footer-resources h5 {
  font-size: 1.8rem;
  margin-bottom: 2rem;
}

.footer-company li,
.footer-product li,
.footer-resources li {
  margin: 1rem 0rem;
  font-size: 1.5rem;
}

.mission-vision-div {
  width: 35%;
} 

.mission-vision-container {
  width: 90%;
  max-width: 35rem;
  height: 100%;
  max-height: 35rem;
}

.mission,
.vision {
  width: 100%;
  height: 50%;
  padding: 0rem 2rem;
}

.mission h5,
.vision h5 {
  font-size: 1.6rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

}
